// https://icon-sets.iconify.design/
// NOTE: @Mxs2019 - When possible try to use tabler icons
import type { SVGProps } from "react";

import { cn } from "@wind/ui";

import AlertIcon from "~icons/ant-design/alert-outlined";
import BsCheck from "~icons/bi/check";
import BiFilter from "~icons/bi/filter";
import BsFillGrid3X3GapFill from "~icons/bi/grid-3x3-gap-fill";
import HourglassIcon from "~icons/bi/hourglass";
import BsJournalText from "~icons/bi/journal-text";
import BsPerson from "~icons/bi/person";
import BsStars from "~icons/bi/stars";
import DollarIcon from "~icons/bx/dollar";
import BranchIcon from "~icons/carbon/branch";
import ErrorIcon from "~icons/codicon/error";
import FaEnvelope from "~icons/fa/envelope";
import FaPodcast from "~icons/fa/podcast";
import FaSlack from "~icons/fa/slack";
import TrashIcon from "~icons/fa/trash-o";
import TestIcon from "~icons/fluent-mdl2/test-beaker-solid";
import ColumnsIcon from "~icons/fluent/column-triple-24-regular";
import ConferenceRoom from "~icons/fluent/conference-room-20-regular";
import GlobeIcon from "~icons/fluent/globe-20-regular";
import SpinnerIcon from "~icons/fluent/spinner-ios-16-regular";
import NumberBoxIcon from "~icons/formkit/number";
import OrgChartIcon from "~icons/game-icons/organigram";
import HashTagIcon from "~icons/heroicons-outline/hashtag";
import Document from "~icons/heroicons/document";
import BarChartIcon from "~icons/ic/baseline-bar-chart";
import ChevronLeftIcon from "~icons/ic/outline-chevron-left";
import ChevronRightIcon from "~icons/ic/outline-chevron-right";
import MdOutlineSyncAlt from "~icons/ic/outline-sync-alt";
import LinkIcon from "~icons/ic/round-link";
import PlusIcon from "~icons/ic/round-plus";
import FaSync from "~icons/ic/twotone-sync";
import ArcheryMatchIcon from "~icons/iconoir/archery-match";
import LeaderboardIcon from "~icons/iconoir/leaderboard";
import SmsIcon from "~icons/iconoir/message-text";
import EmailIcon from "~icons/iconoir/send-mail";
import AnalyticsIcon from "~icons/ion/analytics-outline";
import BiVideoRecording from "~icons/ion/recording-outline";
import TrendingDownIcon from "~icons/ion/trending-down-sharp";
import TrendingUpIcon from "~icons/ion/trending-up-sharp";
import BotIcon from "~icons/lucide/bot";
import LayersIcon from "~icons/lucide/layers";
import PanelLeftCloseIcon from "~icons/lucide/panel-left-close";
import PanelLeftOpenIcon from "~icons/lucide/panel-left-open";
import UnlinkIcon from "~icons/lucide/unlink";
import CardIcon from "~icons/material-symbols-light/id-card-outline";
import SyncIcon from "~icons/material-symbols-light/sync-alt";
import KeyboardSingleArrowRight from "~icons/material-symbols/keyboard-arrow-right";
import KeyboardDoubleArrowRight from "~icons/material-symbols/keyboard-double-arrow-right";
import MergeIcon from "~icons/material-symbols/merge";
import MdThermostat from "~icons/material-symbols/thermostat";
import UndoIcon from "~icons/material-symbols/undo";
import PlusCircleIcon from "~icons/mdi-light/plus-circle";
import BellOffIcon from "~icons/mdi/bell-off-outline";
import BellRingIcon from "~icons/mdi/bell-ring";
import BrainIcon from "~icons/mdi/brain";
import MdOutlineCheck from "~icons/mdi/check-circle-outline";
import MdOutlineCircle from "~icons/mdi/circle-outline";
import MdOutlineHighlightOff from "~icons/mdi/close-circle-outline";
import DotIcon from "~icons/mdi/dot";
import ThreeDotsVerticalIcon from "~icons/mdi/dots-vertical";
import EyeIcon from "~icons/mdi/eye-outline";
import InfoCircleIcon from "~icons/mdi/info-circle-outline";
import PersonCheck from "~icons/mdi/person-check-outline";
import PersonMultipleCheck from "~icons/mdi/person-multiple-check-outline";
import RefreshIcon from "~icons/mdi/refresh";
import ThinkingIcon from "~icons/mdi/thinking-outline";
import TimelineClockIcon from "~icons/mdi/timeline-clock-outline";
import ArrowToDownLine from "~icons/mingcute/arrow-to-down-line";
import ArrowToUpLine from "~icons/mingcute/arrow-to-up-line";
import PlayCircleIcon from "~icons/octicon/play-24";
import HorizontalControlsIcon from "~icons/oui/controls-horizontal";
import PaintBrushIcon from "~icons/ph/paint-brush";
import WrenchIcon from "~icons/ph/wrench";
import ChevronDownPixelIcon from "~icons/pixelarticons/chevron-down";
import ArrowUpBaseIcon from "~icons/radix-icons/arrow-up";
import CalendarIcon from "~icons/radix-icons/calendar";
import CodeIcon from "~icons/radix-icons/code";
import FullScreenIcon from "~icons/radix-icons/enter-full-screen";
import HamburgerIcon from "~icons/radix-icons/hamburger-menu";
import LightningBoltIcon from "~icons/radix-icons/lightning-bolt";
import PaperPlaneIcon from "~icons/radix-icons/paper-plane";
import StarIcon from "~icons/radix-icons/star";
import StarFilledIcon from "~icons/radix-icons/star-filled";
import LinkedInIcon from "~icons/ri/linkedin-box-fill";
import TwitterIcon from "~icons/ri/twitter-x-fill";
import YouTubeIcon from "~icons/ri/youtube-fill";
import KeyIcon from "~icons/solar/key-linear";
import MailboxIcon from "~icons/solar/mailbox-linear";
import TagIcon from "~icons/solar/tag-outline";
import HeartbeatIcon from "~icons/tabler/activity-heartbeat";
import SettingsIcon from "~icons/tabler/adjustments-horizontal";
import BellIcon from "~icons/tabler/bell";
import SuperpowersIcon from "~icons/tabler/brand-supernova";
import DebugIcon from "~icons/tabler/bug";
import BuildingIcon from "~icons/tabler/building";
import LightBulbIcon from "~icons/tabler/bulb";
import CaretUpFilledIcon from "~icons/tabler/caret-up-filled";
import TableIcon from "~icons/tabler/chart-grid-dots";
import CheckIcon from "~icons/tabler/check";
import ChecklistIcon from "~icons/tabler/checklist";
import AccomplishmentsIcon from "~icons/tabler/checks";
import ClockIcon from "~icons/tabler/clock";
import CopyIcon from "~icons/tabler/copy";
import DeviceDesktopAnalyticsBaseIcon from "~icons/tabler/device-desktop-analytics";
import DownloadBaseIcon from "~icons/tabler/download";
import FlagIcon from "~icons/tabler/flag";
import HomeIcon from "~icons/tabler/home-2";
import LockIcon from "~icons/tabler/lock";
import LoginIcon from "~icons/tabler/login";
import LogoutIcon from "~icons/tabler/logout";
import MedalIcon from "~icons/tabler/medal";
import FeedbackReceivedIcon from "~icons/tabler/message-2-down";
import FeedbackGivenIcon from "~icons/tabler/message-2-up";
import MicroscopeIcon from "~icons/tabler/microscope";
import HappyIcon from "~icons/tabler/mood-happy";
import SadIcon from "~icons/tabler/mood-sad-dizzy";
import PencilIcon from "~icons/tabler/pencil";
import PencilBoltIcon from "~icons/tabler/pencil-bolt";
import SearchIcon from "~icons/tabler/search";
import SwitchIcon from "~icons/tabler/switch-3";
import TelescopeIcon from "~icons/tabler/telescope";
import ThumbsDownIcon from "~icons/tabler/thumb-down";
import ThumbsUpIcon from "~icons/tabler/thumb-up";
import UserIcon from "~icons/tabler/user";
import UserCircleIcon from "~icons/tabler/user-circle";
import UserPlusIcon from "~icons/tabler/user-plus";
import UsersIcon from "~icons/tabler/users";
import VectorTriangleIcon from "~icons/tabler/vector-triangle";
import XIcon from "~icons/tabler/x";

const ArrowUpIcon = ({ className, ...props }: SVGProps<SVGSVGElement> = {}) => (
  <ArrowUpBaseIcon {...props} className={cn(className, "text-[0.9em]")} />
);
const ArrowDownIcon = ({ className, ...props }: SVGProps<SVGSVGElement> = {}) => (
  <ArrowUpIcon {...props} className={cn(className, "rotate-180")} />
);
const ArrowLeftIcon = ({ className, ...props }: SVGProps<SVGSVGElement> = {}) => (
  <ArrowUpIcon {...props} className={cn(className, "-rotate-90")} />
);

const ArrowRightIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <ArrowUpIcon {...props} className={cn(props.className, "rotate-90")} />
);

const EditIcon = (props: SVGProps<SVGSVGElement> = {}) => <PencilIcon {...props} />;

const ComparisonIcon = (props: SVGProps<SVGSVGElement> = {}) => <ArcheryMatchIcon {...props} />;

const ActivityIcon = (props: SVGProps<SVGSVGElement> = {}) => <HeartbeatIcon {...props} />;

const NewActivityIcon = (props: SVGProps<SVGSVGElement> = {}) => <TimelineClockIcon {...props} />;

const EmployeesIcon = (props: SVGProps<SVGSVGElement> = {}) => <UsersIcon {...props} />;

const EmployeeIcon = (props: SVGProps<SVGSVGElement> = {}) => <UserIcon {...props} />;

const CostIcon = (props: SVGProps<SVGSVGElement> = {}) => <DollarIcon {...props} />;

const MeetingsIcon = (props: SVGProps<SVGSVGElement> = {}) => <ConferenceRoom {...props} />;

const SummaryIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsJournalText {...props} />;

const SyncIntegrationIcon = (props: SVGProps<SVGSVGElement> = {}) => <FaSync {...props} />;

const AiIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsStars {...props} />;

const NewTabIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <ArrowRightIcon {...props} className={cn(props.className, "-rotate-45")} />
);

const IntegrationsIcon = (props: SVGProps<SVGSVGElement> = {}) => <MdOutlineSyncAlt {...props} />;

const MappingIcon = (props: SVGProps<SVGSVGElement> = {}) => <MdOutlineSyncAlt {...props} />;

const ConnectionsIcon = (props: SVGProps<SVGSVGElement> = {}) => <SyncIcon {...props} />;

const ConnectIcon = (props: SVGProps<SVGSVGElement> = {}) => <SyncIcon {...props} />;

const DisconnectIcon = (props: SVGProps<SVGSVGElement> = {}) => <UnlinkIcon {...props} />;

const RecordingIcon = (props: SVGProps<SVGSVGElement> = {}) => <BiVideoRecording {...props} />;

const DocumentIcon = (props: SVGProps<SVGSVGElement> = {}) => <Document {...props} />;

const FilterIcon = (props: SVGProps<SVGSVGElement> = {}) => <BiFilter {...props} />;

const ChannelIcon = (props: SVGProps<SVGSVGElement> = {}) => <HashTagIcon {...props} />;

const ExternalLinkIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <ArrowUpIcon className={cn(props.className, "rotate-45")} />
);

const HighlightsIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsStars {...props} />;

const PersonIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsPerson {...props} />;

const PersonCheckIcon = (props: SVGProps<SVGSVGElement> = {}) => <PersonCheck {...props} />;

const PersonMultipleCheckIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <PersonMultipleCheck {...props} />
);

const CompanyIcon = (props: SVGProps<SVGSVGElement> = {}) => <BuildingIcon {...props} />;

const OrgsIcon = (props: SVGProps<SVGSVGElement> = {}) => <BuildingIcon {...props} />;

const FeaturesIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsCheck {...props} />;

// Feature states
const FeatureOnIcon = (props: SVGProps<SVGSVGElement> = {}) => <MdOutlineCheck {...props} />;

const FeatureOffIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <MdOutlineHighlightOff {...props} />
);

const FeatureIndeterminateIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <MdOutlineCircle {...props} />
);

const EmailChannelIcon = (props: SVGProps<SVGSVGElement> = {}) => <FaEnvelope {...props} />;

const SlackChannelIcon = (props: SVGProps<SVGSVGElement> = {}) => <FaSlack {...props} />;

const PodcastChannelIcon = (props: SVGProps<SVGSVGElement> = {}) => <FaPodcast {...props} />;

const HealthIcon = (props: SVGProps<SVGSVGElement> = {}) => <MdThermostat {...props} />;

const DeliveryIcon = (props: SVGProps<SVGSVGElement> = {}) => <MailboxIcon {...props} />;

const HomeGridIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsFillGrid3X3GapFill {...props} />;

const ChevronDownIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <ChevronRightIcon {...props} className={cn(props.className, "rotate-90")} />
);

const HeroNumberIcon = (props: SVGProps<SVGSVGElement> = {}) => <NumberBoxIcon {...props} />;

const LoadingIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <SpinnerIcon {...props} className={cn(props.className, "animate-spin")} />
);

const TimeSpentIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <HourglassIcon className={cn(props.className, "text-[0.9em]")} {...props} />
);

const ImpactIcon = (props: SVGProps<SVGSVGElement> = {}) => <LightningBoltIcon {...props} />;

const NotificationsIcon = (props: SVGProps<SVGSVGElement> = {}) => <BellIcon {...props} />;

const SendIcon = (props: SVGProps<SVGSVGElement> = {}) => <PaperPlaneIcon {...props} />;

const ChooseStatsIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <HorizontalControlsIcon {...props} />
);

const StatsIcon = (props: SVGProps<SVGSVGElement> = {}) => <VectorTriangleIcon {...props} />;

const SavedViewIcon = LayersIcon;

const TeamIcon = EmployeesIcon;

const DeepDiveIcon = MicroscopeIcon;

const DownloadIcon = (props: SVGProps<SVGSVGElement> = {}) => <DownloadBaseIcon {...props} />;

const DeviceDesktopAnalyticsIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <DeviceDesktopAnalyticsBaseIcon {...props} />
);

export {
  AccomplishmentsIcon,
  ActivityIcon,
  AlertIcon,
  NewActivityIcon,
  AiIcon,
  AnalyticsIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  ArrowToUpLine,
  ArrowToDownLine,
  BarChartIcon,
  BellOffIcon,
  BellRingIcon,
  BrainIcon,
  BotIcon,
  BranchIcon,
  EmailChannelIcon,
  PodcastChannelIcon,
  SlackChannelIcon,
  CalendarIcon,
  CardIcon,
  CaretUpFilledIcon,
  ChannelIcon,
  CheckIcon,
  ChecklistIcon,
  ChevronDownIcon,
  ChevronDownPixelIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ChooseStatsIcon,
  ClockIcon,
  CodeIcon,
  ColumnsIcon,
  CompanyIcon,
  ConnectIcon,
  ConnectionsIcon,
  CopyIcon,
  ComparisonIcon,
  CostIcon,
  DebugIcon,
  DeepDiveIcon,
  DeliveryIcon,
  DeviceDesktopAnalyticsIcon,
  DisconnectIcon,
  DocumentIcon,
  DotIcon,
  DownloadIcon,
  EditIcon,
  EmailIcon,
  EmployeeIcon,
  EmployeesIcon,
  ErrorIcon,
  ExternalLinkIcon,
  EyeIcon,
  FeatureIndeterminateIcon,
  FeatureOffIcon,
  FeatureOnIcon,
  FeaturesIcon,
  FeedbackGivenIcon,
  FeedbackReceivedIcon,
  FilterIcon,
  FlagIcon,
  FullScreenIcon,
  GlobeIcon,
  HappyIcon,
  HamburgerIcon,
  HealthIcon,
  HeroNumberIcon,
  HighlightsIcon,
  HomeGridIcon,
  HomeIcon,
  InfoCircleIcon,
  IntegrationsIcon,
  ImpactIcon,
  KeyIcon,
  KeyboardDoubleArrowRight,
  KeyboardSingleArrowRight,
  LeaderboardIcon,
  LightBulbIcon,
  LinkIcon,
  LightningBoltIcon,
  LoadingIcon,
  LockIcon,
  LogoutIcon,
  LoginIcon,
  LinkedInIcon,
  MailboxIcon,
  MappingIcon,
  MedalIcon,
  MeetingsIcon,
  MergeIcon,
  NewTabIcon,
  NotificationsIcon,
  OrgChartIcon,
  OrgsIcon,
  PaintBrushIcon,
  PanelLeftCloseIcon,
  PanelLeftOpenIcon,
  PencilIcon,
  PencilBoltIcon,
  PersonIcon,
  PersonCheckIcon,
  PersonMultipleCheckIcon,
  PlayCircleIcon,
  PlusCircleIcon,
  PlusIcon,
  RecordingIcon,
  RefreshIcon,
  SadIcon,
  SavedViewIcon,
  SearchIcon,
  SpinnerIcon,
  SettingsIcon,
  SendIcon,
  SmsIcon,
  StarFilledIcon,
  StarIcon,
  StatsIcon,
  SummaryIcon,
  SuperpowersIcon,
  SwitchIcon,
  SyncIntegrationIcon,
  TableIcon,
  TagIcon,
  TelescopeIcon,
  ThinkingIcon,
  TeamIcon,
  TestIcon,
  TimeSpentIcon,
  ThreeDotsVerticalIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
  TrashIcon,
  TrendingUpIcon,
  TrendingDownIcon,
  TwitterIcon,
  UndoIcon,
  UserIcon,
  UserPlusIcon,
  WrenchIcon,
  YouTubeIcon,
  XIcon,
  UserCircleIcon,
};
